.section-head {
	font-weight: bold;
	font-size: 4rem;
}

.section-style {
	margin-top: 5%;
	font-family: 'Comfortaa', cursive;
}

@media screen and (max-width: 700px) {
	.section-head {
		font-size: 3rem;
	}
}

.blue-color {
	color: rgb(83, 125, 248);
	font-weight: 700;
}

.grabbable {
	cursor: grab;
	z-index: 100;
}

.grabbable:active {
	cursor: grabbing;
}

.resume-page {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}