@import url(https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Comfortaa:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.section-head {
	font-weight: bold;
	font-size: 4rem;
}

.section-style {
	margin-top: 5%;
	font-family: 'Comfortaa', cursive;
}

@media screen and (max-width: 700px) {
	.section-head {
		font-size: 3rem;
	}
}

.blue-color {
	color: rgb(83, 125, 248);
	font-weight: 700;
}

.grabbable {
	cursor: grab;
	z-index: 100;
}

.grabbable:active {
	cursor: grabbing;
}

.resume-page {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.footer {
	background-color: black;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5%;
	padding: 30px 0;
	font-family: 'Do Hyeon', sans-serif;
	font-size: 1rem;
	letter-spacing: 1.5px;
}
.nav-bar .MuiFormControlLabel-root {
	z-index: 1;
}

.MuiFormControlLabel-label {
	font-family: 'Do Hyeon', sans-serif !important;
	font-size: 1.2rem !important;
}

@media screen and (max-width: 450px) {
	.MuiFormControlLabel-label {
		font-size: 1rem !important;
	}
}

/* .MuiSwitch-track {
	background-color: white !important;
} */

.text-white {
	color: white !important;
}

.text-black {
	color: black !important;
}
.nav-bar {
	display: flex;
	width: 100%;
}

.toolbar {
	justify-content: space-between;
}

.nav-title {
	font-family: 'Do Hyeon', sans-serif !important;
	font-size: 1.5rem;
	margin-left: 3%;
	transition: all .3s ease;
}

@media screen and (max-width: 450px) {
	.nav-title {
		font-size: 1rem;
	}
}

.d-none {
	display: none;
}
.title-section {
    height: 80vh;
    width: 100vw;
    background-color: black;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    overflow: hidden;
    flex-direction: column;
    position: relative;
    line-height: 1.3;
}

.title-container {
    z-index: 1;
    margin-left: 5%;
}

.title-text {
    display: inline-block;
    font-size: 5rem;
    text-transform: uppercase;
    font-family: 'Do Hyeon', sans-serif;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4), 1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2), 1px 30px 60px rgba(16, 16, 16, 0.4);
    z-index: 1;
    width: auto !important;
    margin: 30px 0;
    letter-spacing: 10px;
}

.subtitle-text {
    display: inline-block;
    font-size: 1.3rem;
    font-family: 'Do Hyeon', sans-serif;
    color: white;
    z-index: 1;
    width: auto !important;
    margin: 10px 0;
    letter-spacing: 4px;
}

@media screen and (max-width: 900px) {
    .title-text {
        font-size: 4rem;
    }
    .subtitle-text {
        font-size: 1rem;
    }
}

.astro-img {
    position: absolute;
    width: 200px;
    right: 30px;
    bottom: 0;
    z-index: 2;
}

.scroll-div {
    width: 100%;
    position: absolute;
    bottom: 50px;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .title-text {
        font-size: 2.5rem;
    }
    .astro-img {
        width: 100px;
        right: 10px;
    }
}

@media screen and (max-width: 400px) {
    .title-container {
        margin-right: 1%;
    }
}
.particles {
	position: absolute;
	width: 100%;
	height: 80vh;
}

.particles canvas {
	z-index: -1;
	height: 80vh !important;
}
.scroll-downs {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 34px;
	height: 55px;
	z-index: 1;
}

.mousey {
	width: 3px;
	padding: 5px 10px;
	height: 35px;
	border: 2px solid #fff;
	border-radius: 25px;
	opacity: 0.75;
	box-sizing: content-box;
}

.scroller {
	width: 3px;
	height: 10px;
	border-radius: 25%;
	background-color: #fff;
	animation-name: scroll;
	animation-duration: 2.2s;
	animation-timing-function: cubic-bezier(.15, .41, .69, .94);
	animation-iteration-count: infinite;
}

@keyframes scroll {
	0% {
		opacity: 0;
	}
	10% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(10px);
		opacity: 0;
	}
}
.about-content {
	text-align: justify;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	padding-bottom: 10%;
}

.about-text-div {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 30px;
}

.profile-img-div {
	display: flex;
	justify-content: center;
	width: 100%;
}

.profile-img-div img {
	width: 350px;
	height: 350px;
}



.btn-container {
	width: 100%;
}

@media screen and (max-width: 600px) {
	.profile-img-div img {
		width: 200px;
		height: 200px;
	}

	.btn-container {
		display: flex;
		justify-content: center;
	}
}


.cv-btn {
	background-color: rgb(83, 125, 248);
	border: none;
	color: white;
	font-weight: 700;
	padding: 15px 10px;
	font-size: 1.2rem;
	border-radius: 10px;
	font-family: "Comfortaa";
	cursor: pointer;
	width: 300px;
	margin-top: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.cv-btn:hover {
	background-color: rgb(62, 95, 192);
}

.down-icon {
	margin-right: 10px;
}
.contact-head {
	text-align: right;
}

.img-text {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 1.5rem;
}

.img-text a {
	text-decoration: none;
}

.img-text img {
	margin-left: 20px;
}

.contact-section p {
	margin-top: 5px;
	margin-bottom: 5px;
}

.contact-desc {
	display: flex;
	align-items: center;
}

.contact-text {
	font-size: 1.5rem;
}

@media screen and (max-width: 900px) {
	.img-text {
		font-size: 1.2rem;
	}
	.contact-text {
		font-size: 1rem;
	}
	.img-text img {
		margin-left: 20px;
		width: 32px !important;
	}
}

@media screen and (max-width: 600px) {
	.contact-section {
		margin-top: 30px;
	}
}
.MuiCardMedia-root {
	background-size: contain !important;
}

.projects-list {
	background-color: rgb(194, 194, 194) !important;
	border-radius: 5px;
	padding: 5% !important;
	margin-bottom: 5% !important;
}

.project-grid-list {
	flex-grow: 1 !important;
	justify-content: center !important;
}

.do-font {
	font-family: 'Do Hyeon', sans-serif !important;
}

.project-title .MuiTypography-h5 {
	font-family: 'Do Hyeon', sans-serif !important;
	letter-spacing: 2px;
}

.action-bar {
	justify-content: space-between !important;
	padding-right: 5% !important;
	padding-left: 5% !important;
}

.project-link-btn {
	/* border-radius: 0 !important; */
	box-shadow: none !important;
	color: white !important;
	background-color: orange !important;
	font-family: 'Do Hyeon', sans-serif !important;
	letter-spacing: 1.5px !important;
	z-index: 0;

}

.project-link-btn:disabled {
	background-color: rgb(145, 144, 144) !important;
	color: rgb(228, 228, 228) !important;
}

.git-icon:hover {
	transform: scale(1.2);
}
.skill-head {
    text-align: right;
}

/* .skill-bar {
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.12) !important;
} */

.skill-section {
    padding-bottom: 5%;
}

.skill-bar.MuiAppBar-colorPrimary {
    color: black !important;
    background-color: #eeeeee !important;
}

.MuiTab-wrapper {
    font-family: 'Do Hyeon', sans-serif !important;
    font-size: 1.1rem !important;
    font-weight: bold !important;
    letter-spacing: 2px !important;
}

@media screen and (max-width: 768px) {
    .MuiTab-wrapper {
        font-size: 0.8rem !important;
    }
}
.skill-panel {
	background-color: white;
	box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 7px 8px 0px rgba(0, 0, 0, 0.12) !important;
	padding: 0 5% !important;
	padding-top: 50px !important;
	padding-bottom: 30px !important;
}

.skill-tag {
	font-family: 'Do Hyeon', sans-serif !important;
	font-size: 1.5rem;
}

.skill-item {
	text-align: center !important;
}

.grid-container {
	display: flex;
	justify-content: center;
}
