.scroll-downs {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 34px;
	height: 55px;
	z-index: 1;
}

.mousey {
	width: 3px;
	padding: 5px 10px;
	height: 35px;
	border: 2px solid #fff;
	border-radius: 25px;
	opacity: 0.75;
	box-sizing: content-box;
}

.scroller {
	width: 3px;
	height: 10px;
	border-radius: 25%;
	background-color: #fff;
	animation-name: scroll;
	animation-duration: 2.2s;
	animation-timing-function: cubic-bezier(.15, .41, .69, .94);
	animation-iteration-count: infinite;
}

@keyframes scroll {
	0% {
		opacity: 0;
	}
	10% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(10px);
		opacity: 0;
	}
}