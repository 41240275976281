.nav-bar .MuiFormControlLabel-root {
	z-index: 1;
}

.MuiFormControlLabel-label {
	font-family: 'Do Hyeon', sans-serif !important;
	font-size: 1.2rem !important;
}

@media screen and (max-width: 450px) {
	.MuiFormControlLabel-label {
		font-size: 1rem !important;
	}
}

/* .MuiSwitch-track {
	background-color: white !important;
} */

.text-white {
	color: white !important;
}

.text-black {
	color: black !important;
}